import { Vue } from 'vue-class-component'
import MembersDataService from '@/services/MembersDataService'
import SysMember from '@/types/SysMember'
import CommonFunctions from '@/components/Utility/Common'
import { Watch } from 'vue-property-decorator'

export default class QuarantineMemberList extends Vue {
  public medlemers: SysMember[] = []
  private oldSort = ''
  private oldSortForeign = ''
  private currentListSortingOrder = 'updated_at:desc'
  private currentListSortingOrderForeign = 'updated_at:desc'
  private currentPage = 1
  private totalPages = 0
  private extraParameter = ''
  private extraParameter2 = ''
  private totalNumberOfPages = 0
  error: any = null
  filterStatusStringValue = 'Vis alt'
  clubRoleFilterValue = 'Vis alt'
  pageSizeValue = 25
  pageSizeValueForeign = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']

  data () {
    return {
      medlemers: this.medlemers,
      error: this.error
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.getQuantineMember()
    CommonFunctions.scrollToTop()
  }

  @Watch('pageSizeValueStringForeign')
  onForeignPageSizeValueChange (newVal: string) : void {
    this.pageSizeValueForeign = Number(newVal)
    CommonFunctions.scrollToTop()
  }

  public columnSort (sortBy: string): void {
    if (sortBy === 'navn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder =
          'user_id.lastname:asc,user_id.firstname:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder =
          'user_id.lastname:desc,user_id.firstname:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'karantaene') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_karantaene_slutdato:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_karantaene_slutdato:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'medlem_karantaene') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_karantaene:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_karantaene:asc'
        this.oldSort = ''
      }
    }

    this.getQuantineMember()
    CommonFunctions.scrollToTop()
  }

  public forceRerenderMembersList (): void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public getQuantineMember (): void {
    let countFilter = ''
    if (this.filterStatusStringValue === 'Vis alt') {
      countFilter = (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2))
    } else if (this.filterStatusStringValue === 'Aktiv licens') {
      countFilter = 'medlem_status=true&' + (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2))
    } else {
      countFilter = 'medlem_status=false&' + (this.extraParameter + (this.extraParameter === '' ? this.extraParameter2 : '&' + this.extraParameter2))
    }
    MembersDataService.getMemCount(countFilter)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })
    MembersDataService.findQuarentineMember()
      .then(response => {
        this.medlemers = response.data
      })
      .catch(err => {
        this.error = err
      })
  }

  public testActiveQuarantine (startDate: string, endDate: string): boolean {
    const d = new Date().toISOString()

    if (startDate !== null && endDate !== null) {
      if (
        new Date(startDate).toISOString() < d &&
        new Date(endDate).toISOString() > d
      ) {
        return true
      }
    }

    return false
  }

  public findClub (): void {
    console.log('Search menber.')
    this.getQuantineMember()
  }

  public toDanishDateString (dateString: string): string {
    return CommonFunctions.toDanishDateString(dateString, 2)
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.getQuantineMember()
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.getQuantineMember()
  }

  mounted (): void {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    this.getQuantineMember()
    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.getQuantineMember()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
