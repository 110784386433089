
import { Options, Vue } from 'vue-class-component'
import theQuarantine from '@/components/Frontend/Quarantine/index.vue'
import theRankings from '@/components/Frontend/Ranking/index.vue'

  @Options({
    components: {
      theQuarantine
    }
  })
export default class QuarantineMemberList extends Vue {}
